import React from 'react';

const IconFacebook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" width="14.2222in" height="14.2222in" viewBox="0 0 14222 14222">
    <title>Facebook</title>
    <g>
      <path d="M14222 7111c0,-3927 -3184,-7111 -7111,-7111 -3927,0 -7111,3184 -7111,7111 0,3549 2600,6491 6000,7025l0 -4969 -1806 0 0 -2056 1806 0 0 -1567c0,-1782 1062,-2767 2686,-2767 778,0 1592,139 1592,139l0 1750 -897 0c-883,0 -1159,548 -1159,1111l0 1334 1972 0 -315 2056 -1657 0 0 4969c3400,-533 6000,-3475 6000,-7025z"/>
    </g>
  </svg>
);

export default IconFacebook;
