import React from 'react';

const IconCertificate = () => (
  <svg xmlns="http://www.w3.org/2000/svg"  role="img" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
    <title>certificate</title>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
      <path d="M1364 5111 c-47 -12 -133 -62 -160 -94 -14 -17 -37 -53 -52 -81 l-27
-51 0 -1925 0 -1925 34 -63 c37 -69 79 -109 151 -144 l45 -23 683 -3 682 -3 0
-397 0 -398 200 200 200 200 200 -200 200 -200 0 397 0 397 203 4 c190 3 205
5 252 28 64 31 124 91 155 155 l25 50 3 1483 2 1482 -480 560 -480 560 -903
-1 c-496 -1 -916 -4 -933 -8z m1678 -593 l3 -443 25 -50 c31 -64 91 -124 155
-155 l50 -25 363 -3 362 -3 -2 -1389 c-3 -1385 -3 -1389 -24 -1416 -49 -66
-58 -69 -265 -72 l-189 -3 0 82 c0 80 1 83 40 134 173 224 154 540 -46 739
-218 219 -567 219 -787 1 -201 -199 -221 -515 -47 -740 39 -51 40 -54 40 -134
l0 -81 -669 2 c-658 3 -670 3 -697 24 -15 11 -37 33 -48 48 l-21 27 -3 1872
c-1 1030 0 1886 3 1904 7 40 49 91 90 109 24 11 190 13 847 14 l817 0 3 -442z
m551 -98 l355 -415 -310 -3 c-346 -3 -355 -1 -406 66 l-27 35 -3 386 c-3 385
-3 386 17 366 11 -11 179 -207 374 -435z m-309 -2538 c91 -42 149 -100 194
-190 35 -72 37 -81 37 -172 0 -91 -2 -100 -37 -172 -45 -91 -103 -147 -196
-191 -61 -29 -76 -32 -162 -32 -86 0 -101 3 -162 32 -93 44 -151 100 -196 191
-35 72 -37 81 -37 171 0 87 3 102 32 163 40 85 97 147 172 186 78 42 122 52
213 48 61 -3 92 -11 142 -34z m-339 -894 c49 -18 81 -22 175 -22 94 0 126 4
175 22 32 12 61 22 62 22 2 0 3 -141 3 -312 l0 -313 -120 120 -120 120 -120
-120 -120 -120 0 313 c0 171 1 312 3 312 1 0 30 -10 62 -22z"/>
      <path d="M1600 4400 l0 -80 560 0 560 0 0 80 0 80 -560 0 -560 0 0 -80z"/>
      <path d="M1600 3920 l0 -80 560 0 560 0 0 80 0 80 -560 0 -560 0 0 -80z"/>
      <path d="M1600 3440 l0 -80 1040 0 1040 0 0 80 0 80 -1040 0 -1040 0 0 -80z"/>
      <path d="M1600 2960 l0 -80 1040 0 1040 0 0 80 0 80 -1040 0 -1040 0 0 -80z"/>
      <path d="M1600 2480 l0 -80 1040 0 1040 0 0 80 0 80 -1040 0 -1040 0 0 -80z"/>
    </g>
  </svg>
);

export default IconCertificate;
