import React from 'react';

const IconUpwork = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 307 306" width="307" height="306">
    <title>Upwork</title>
    <g>
      <path d="M156 1.5C183 1.9 198.5 5.6 221.5 17C262.6 37.5 293.3 77.4 302.4 122.1C309.6 157.7 304.1 194.3 286.6 226.5C265.8 264.6 227.5 292.8 183.7 302.2C169.8 305.2 139.1 305.4 125.5 302.7C95.8 296.7 70.1 283.4 48.8 263.1C3.8 220.3 -10.8 155.7 11.5 97.8C27.5 56.3 62.8 22.7 105.1 8.7C114 5.7 119.1 4.5 135 1.6C136.4 1.4 145.8 1.3 156 1.5ZM79.5 93.5L68.4 93.2C62.4 93.1 57.1 93.3 56.7 93.6C56.3 94 56 108.3 56 125.4C56 159.3 56.8 165.9 61.6 175.8C63 178.7 66.6 183.6 69.5 186.6C84.1 201.5 107.5 204.2 124.8 193.1C138.8 184 146 170.1 146 151.7L146 144.5L150.1 152C152.4 156.1 155.9 162.1 158.1 165.2C160.2 168.4 162 171.8 162 172.8C162 173.9 158.9 189.2 155 206.9C151.2 224.5 148 239.1 148 239.3C148 239.4 153.4 239.4 159.9 239.2L171.9 238.9L172.4 236.2C180.1 200.2 181.9 191.8 182.3 191.4C182.5 191.2 185.6 192.3 189.1 193.8C198.1 197.9 204.1 199.1 214 199.1C231 199.1 244.4 191.5 254.1 176.2C260.4 166.2 262.6 151.8 259.6 140.4C253.8 118.5 230.6 103.3 208.6 106.9C198.9 108.5 192.6 111.6 185.2 118.4C179.4 123.8 175.4 130.2 171.3 141C170.4 143.3 170.1 143 166 136C160.4 126.4 154.5 113.7 150.5 102.5L147.2 93.5L124 92.9L124 125.2C124 145.7 123.6 159 122.9 161.5C121.3 167.1 113.8 174.4 108 176C98.9 178.5 88.4 174.5 83.2 166.8L80.5 162.7L79.5 93.5Z"/>
      <path d="M205.5 130.9C200.9 132.9 195.4 138.8 193.4 143.7C192.6 145.5 191 151.2 189.9 156.2L187.8 165.3L190.1 167.2C197.3 172.8 209.8 176.5 217.2 175.4C222.4 174.5 229.7 169.6 233.3 164.3C237.4 158.6 238.2 148.3 235.2 142.4C229.5 131.1 216.2 126 205.5 130.9Z"/>
    </g>
  </svg>
);

export default IconUpwork;
