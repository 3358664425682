import React from 'react';

const IconWhatsApp = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" width="256.000000pt" height="256.000000pt" viewBox="0 0 256.000000 256.000000" >
    <title>WhatsApp</title>
    <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)">
      <path d="M1095 2505 c-135 -21 -235 -53 -360 -115 -142 -71 -231 -135 -342
        -247 -359 -363 -453 -918 -236 -1385 l36 -78 -58 -213 c-63 -232 -64 -255 -22
        -318 29 -44 92 -79 141 -79 21 0 132 25 247 55 l209 56 37 -19 c183 -93 469
        -140 678 -113 290 38 571 179 757 380 317 343 419 811 272 1251 -78 235 -222
        434 -423 586 -100 76 -294 174 -401 204 -175 48 -371 61 -535 35z m361 -120
        c242 -39 442 -142 614 -314 440 -442 440 -1141 0 -1581 -98 -98 -191 -163
        -322 -225 -296 -140 -663 -137 -950 6 -32 16 -68 29 -81 29 -13 0 -118 -25
        -233 -55 -115 -30 -218 -55 -228 -55 -26 0 -56 29 -56 54 0 12 25 111 55 221
        30 110 55 210 55 223 0 12 -14 50 -30 83 -46 90 -89 227 -105 336 -83 547 236
        1066 761 1238 159 52 352 67 520 40z"/>
      <path d="M1155 2213 c-134 -21 -301 -85 -402 -154 -69 -47 -188 -164 -239
        -236 -58 -80 -117 -207 -146 -313 -18 -70 -22 -110 -22 -225 0 -173 22 -273
        95 -425 66 -138 68 -165 26 -317 -21 -76 -30 -123 -23 -123 6 0 61 14 121 30
        149 40 176 39 299 -14 164 -72 247 -90 416 -90 120 0 159 4 230 23 174 47 322
        133 441 258 123 129 196 257 240 423 33 123 33 338 1 460 -29 106 -88 233
        -146 313 -51 72 -170 189 -239 236 -71 49 -206 109 -294 132 -77 20 -289 34
        -358 22z m-217 -389 c13 -4 31 -23 41 -43 34 -67 101 -237 101 -257 0 -22 -19
        -53 -67 -107 -18 -20 -33 -42 -33 -48 0 -23 94 -150 154 -207 94 -90 232 -172
        290 -172 17 0 41 22 85 75 34 41 70 75 79 75 9 0 78 -31 152 -68 151 -75 149
        -73 130 -168 -15 -69 -57 -119 -135 -158 -86 -44 -174 -41 -335 13 -146 49
        -238 99 -340 184 -133 112 -292 317 -348 450 -35 84 -38 219 -6 288 53 114
        139 167 232 143z"/>
    </g>
  </svg>
);

export default IconWhatsApp;
